import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useAuth } from '../../services/contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import useSWR from 'swr'
import LogoLoader from '../../components/LogoLoader'
import UserTable from './UserTable'
import { HeaderTypography, TableContainer } from './components'

function Users() {
  const { t } = useTranslation()
  const { token } = useAuth()

  const { data } = useSWR(
    token ? ['/users', token, 'contractManagement'] : null,
    {
      revalidateOnMount: true,
    }
  )

  return (
    <>
      <Helmet title="Nutzers" />

      <Grid container spacing={10} sx={{ visibility: 'transparent' }}>
        <Grid item mb={14} ml={4}>
          <HeaderTypography variant="h2" gutterBottom display="inline">
            {t('LBLUserMgmt')}
          </HeaderTypography>
        </Grid>
        {/* <Grid item mb={6}>
          <div>
            <CustomizedButton variant="outlined">
              <AddIcon />
              {t('LBLCreateUser')}
            </CustomizedButton>
          </div>
        </Grid> */}
      </Grid>
      {!data ? (
        <div style={{ height: '100vh' }}>
          <LogoLoader loading={true} />
        </div>
      ) : (
        <TableContainer variant="outlined">
          <UserTable contract={data} />
        </TableContainer>
      )}
    </>
  )
}

export default Users
