import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Outlet } from 'react-router-dom'

import { Box, CssBaseline } from '@mui/material'

import GlobalStyle from '../components/GlobalStyle'
import Navbar from '../components/navbar/Navbar'
import dashboardItems from '../components/sidebar/dashboardItems'
import Sidebar from '../components/sidebar/Sidebar'
import Footer from '../components/Footer'

const drawerWidth = 280

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: ${(props) => props.theme.tokens.surfaceDim};
`

//this is the side div on the left side of screen
const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
    background-color: ${(props) => props.theme.tokens.surfaceDim};
  }
`

const AppContent = styled.div`
  background-color: ${(props) => props.theme.tokens.surfaceDim};
  z-index: 1;
  padding-top: 120px;
  padding-left: 60px;
  border-bottom: 1px solid ${(props) => props.theme.tokens.outline};
`

const Dashboard = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={dashboardItems}
          />
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Sidebar
            PaperProps={{
              style: {
                width: 244,
                left: 30,
                top: 30,
                height: '72vh',
                borderRadius: 24,
                border: '1px solid rgba(0, 0, 0, 0.1)',
                boxShadow: '0px 5px 14px 0px rgba(0, 0, 0, 0.05)',
              },
            }}
            items={dashboardItems}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        {children}
        <Outlet />
        <Footer />
      </AppContent>
    </Root>
  )
}

export default Dashboard
